
 .Carousel .slick-slider {
    background-color: transparent;
    margin: 20px;
    
  }
  .Carousel .slick-slider .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 340px;
    justify-content: center;
  }
  .Carousel .slick-slider .slick-slide {
    margin: 20px;
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0px;
    background-color: white;
    transition: all 0.3s ease;
    height: auto;
    text-align: center;
  }
  /* .Carousel .slick-slider .slick-slide .slick-active img{
    height: 95vh;
    object-fit: cover;
  } */
  .slick-slide  img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

.slick-center  img {
  height: 340px;
}